/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import homepageService from '@services/homepage';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import SearchBar from '@components/SearchBar';
import MyDialog from '@components/MyDialog';
import { Delete } from '@mui/icons-material';
import HelmetContainer from '@components/HelmetContainer';
// import MyTable from '@components/MyTable';
import MyTable from '@components/MyTable';
import { setError, setSuccess } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { columns, recruitDepartmentList } from './enhance';

export default function Careers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialog, setDialog] = useState({
    openConfirm: false,
    update: false,
    register: false,
    action: 'register'
  });
  const [pagination, setPagination] = useState({
    count: 0,
    totalRows: 0,
    page: 0
  });
  const [param, setParam] = useState({
    departmentType: 'all'
  });
  const [loading, setLoading] = useState(false);

  const handleChangeCheckbox = (val) => {
    setParam((prevParam) => ({
      ...prevParam,
      departmentType: prevParam.departmentType === val ? null : val
    }));
  };

  const getData = async (params) => {
    setLoading(true);
    const result = await homepageService.getCareerList(params);
    if (result.error) dispatch(setError(error2Text(result.error)));
    else {
      setData(result.data?.items);
      setPagination({
        ...pagination,
        page: result.data?.page || 0,
        totalRows: result.data?.totalRow || 0,
        count: result.data?.totalPage || 0
      });
    }
    setLoading(false);
  };

  const handleSearch = () => {
    const query = {
      departmentType: param?.departmentType,
      page: pagination.page + 1
    };

    navigate(objToParams(ignoreEmpty(query)));
    getData({
      departmentType:
        param?.departmentType !== 'all' ? param.departmentType : null,
      page: 0
    });
  };

  const handleRowClick = (row) => {
    navigate(row?.recruitCode);
  };

  const deleteJobPosition = async () => {
    // change from the list
    const rs = await homepageService.deleteJobPost(selectedItem?.recruitCode);
    if (rs?.error) {
      getData();
    } else {
      setSelectedItem(null);
      setDialog({ ...dialog, openConfirm: false });
      dispatch(setSuccess({ message: t('info.delete.content') }));
      getData();
    }
  };
  const getActionButtons = (item, actionType) => {
    switch (actionType) {
      default:
        return (
          <IconButton
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(item);
              setDialog({ ...dialog, openConfirm: true });
            }}
          >
            <Delete />
          </IconButton>
        );
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { departmentType, page } = query;
    const dataParams = {
      departmentType: departmentType === 'all' ? null : departmentType,
      page: page - 1 || 0
    };
    setParam({ ...param, departmentType: departmentType || 'all' });
    setPagination({ ...pagination, page: page - 1 || 0 });
    getData(dataParams);
  }, [location?.search]);

  return (
    <HelmetContainer
      title={t('pageTitle.talent-hire')}
      content="homepage careers"
    >
      <SearchBar>
        <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            label={t('pages.homepage.recruit-department.label')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 2
              }
            }}
            control={
              <Stack flexDirection="row">
                {recruitDepartmentList.map(({ value, label }) => (
                  <FormControlLabel
                    key={`${value}-${label}`}
                    control={
                      <Checkbox
                        disableRipple
                        checked={param.departmentType === value}
                        onChange={() => handleChangeCheckbox(value)}
                      />
                    }
                    label={label}
                  />
                ))}
              </Stack>
            }
          />
        </FormControl>
        <Button variant="contained" onClick={handleSearch}>
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: '30px', mb: 2 }}
      >
        <Button variant="contained" onClick={() => navigate('register')}>
          {t('button.register')}
        </Button>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {pagination?.totalRows || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <MyTable
        rerender
        hover
        columns={columns}
        data={data || []}
        getActionButtons={getActionButtons}
        onRowClick={handleRowClick}
        pagination={pagination}
        onChangePage={(e, page) => {
          const query = objToParams(ignoreEmpty({ page: page + 1 }));
          setPagination({ ...pagination, page });
          getData({ page, size: 20 });
          navigate(query);
        }}
        minusHeight={235}
      />
      {selectedItem && (
        <MyDialog
          open={dialog.openConfirm}
          setOpen={() => setDialog({ ...dialog, openConfirm: false })}
          onOk={deleteJobPosition}
          isAlert
          title={t('common.confirm-delete-title', {
            item: selectedItem?.title
          })}
          hasCancelButton
        />
      )}
    </HelmetContainer>
  );
}
