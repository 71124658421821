import { tableTypes } from '@constants/utils';
import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages.homepage');

export const requiredFields = [
  'departmentType',
  'employmentType',
  'yearOfExperience',
  'regionCode',
  'title',
  'content'
];
export const recruitDepartmentList = [
  {
    value: 'all',
    label: t('recruit-department.all')
  },
  {
    value: 'Business',
    label: t('recruit-department.business')
  },
  {
    value: 'Sales',
    label: t('recruit-department.sales')
  },
  {
    value: 'VendingMachine',
    label: t('recruit-department.vending-machine-engineer')
  },

  {
    value: 'Tech',
    label: t('recruit-department.tech')
  },
  {
    value: 'Scm',
    label: t('recruit-department.scm')
  },

  {
    value: 'TalentPool',
    label: t('recruit-department.talent')
  }
];

export const employmenTypeObject = {
  FullTime: t('employment-types.full-time'),
  Contract: t('employment-types.contract'),
  PartTime: t('employment-types.part-time'),
  Intern: t('employment-types.intern')
};

export const departmentTypeObject = {
  Business: t('recruit-department.business'),
  Sales: t('recruit-department.sales'),
  Tech: t('recruit-department.tech'),
  Scm: t('recruit-department.scm'),
  VendingMachine: t('recruit-department.vending-machine-engineer'),
  TalentPool: t('recruit-department.talent')
};

export const employmentTypeList = [
  {
    value: 'FullTime',
    label: t('employment-types.full-time')
  },
  {
    value: 'Contract',
    label: t('employment-types.contract')
  },
  {
    value: 'PartTime',
    label: t('employment-types.part-time')
  },
  {
    value: 'Intern',
    label: t('employment-types.intern')
  }
];

export const columns = [
  {
    name: '#',
    label: 'No',
    minWidth: 80,
    align: 'center'
  },
  {
    name: 'departmentType',
    label: t('department'),
    minWidth: 100,
    align: 'center',
    render: ({ departmentType }) =>
      departmentType && (departmentTypeObject[departmentType] || departmentType)
  },
  {
    name: 'title',
    label: t('announcement-name'),
    minWidth: 310,
    align: 'center'
  },
  {
    name: 'yearOfExperience',
    label: t('experience-details'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'employmentType',
    label: t('employment-type'),
    minWidth: 100,
    align: 'center',
    render: ({ employmentType }) =>
      employmentType && (employmenTypeObject[employmentType] || employmentType)
  },
  {
    name: 'regionName',
    label: t('location'),
    minWidth: 100,
    align: 'center'
  },
  {
    name: 'endDate',
    label: t('end-date'),
    minWidth: 100,
    align: 'center',
    render: ({ deadline }) =>
      deadline ? Formatter.formatTime(deadline, 'YYYY.MM.DD') : t('always')
  },
  {
    name: 'createdBy',
    label: t('register-admin'),
    minWidth: 120,
    align: 'center'
  },

  {
    name: 'createdDate',
    label: t('reg-dt'),
    minWidth: 150,
    align: 'center',
    render: ({ createdDate }) =>
      new Date(createdDate).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    name: 'delete',
    label: g('button.delete'),
    minWidth: 80,
    align: 'center',
    type: tableTypes.ACTION
  }
];

export const experienceList = [
  { value: '경력무관', label: t('regardless-of-experience') },
  { value: '1년 이상', label: `1${t('more-than')}` },
  { value: '2년 이상', label: `2${t('more-than')}` },
  { value: '3년 이상', label: `3${t('more-than')}` },
  { value: '4년 이상', label: `4${t('more-than')}` },
  { value: '5년 이상', label: `5${t('more-than')}` },
  { value: '6년 이상', label: `6${t('more-than')}` },
  { value: '7년 이상', label: `7${t('more-than')}` },
  { value: '8년 이상', label: `8${t('more-than')}` },
  { value: '9년 이상', label: `9${t('more-than')}` },
  { value: '10년 이상', label: `10${t('more-than')}` }
];
export const productDetailSchema = [
  {
    name: 'createdBy',
    type: 'text',
    label: t('register-admin'),
    disabled: true,
    grid: 3,

    border: { borderLeft: 0 }
  },
  {
    name: 'updatedBy',
    type: 'text',
    label: t('edit-admin'),
    disabled: true,
    grid: 3,
    border: { borderLeft: '1px solid #D3D3D3' }
  },
  {
    name: 'createdDate',
    type: 'text',
    label: t('reg-dt'),
    disabled: true,
    grid: 3,
    border: { borderLeft: '1px solid #D3D3D3' },
    formatter: (value) => value && Formatter.formatTime(value)
  },
  {
    name: 'updatedDate',
    type: 'text',
    label: t('edit-dt'),
    disabled: true,
    formatter: (value) => value && Formatter.formatTime(value),
    grid: 3
  },

  {
    name: 'departmentType',
    type: 'select',
    label: t('department'),

    options: (data) => data?.department || [],
    grid: 3,

    border: { borderLeft: 0 }
  },
  {
    name: 'yearOfExperience',
    type: 'select',
    label: t('experience-details'),

    options: (data) => data?.experience || [],
    grid: 3,
    border: { borderLeft: '1px solid #D3D3D3' }
  },
  {
    name: 'employmentType',
    type: 'select',
    label: t('employment-type'),

    options: (data) => data?.employment || [],
    grid: 3,
    border: { borderLeft: '1px solid #D3D3D3' }
  },
  {
    name: 'regionCode',
    type: 'select',
    label: t('location'),
    options: (data) => data?.location || [],
    grid: 3
  },
  {
    name: 'title',
    type: 'text',
    label: t('announcement-name'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'deadline',
    type: 'date',
    label: t('end-date'),
    grid: 6,
    border: { borderLeft: '1px solid #D3D3D3' }
  },

  {
    name: 'content',
    type: 'textarea',
    label: t('announcement-content'),
    grid: 12,
    border: { borderLeft: 0 }
  }
];
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
