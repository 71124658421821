import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FormControl,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material';

import DateRangePicker from '@components/DateRangePicker';
import HelmetContainer from '@components/HelmetContainer';
import MySelect from '@components/MySelect';
import MyTextField from '@components/MyTextField';
import SearchBar from '@components/SearchBar';
import { sub } from 'date-fns';
import { dateFormat } from '@constants/utils';
import consultationInquiryService from '@services/consultationInquiry';
import { Formatter } from '@utils/formatter';
import { useDispatch } from 'react-redux';
import { ignoreEmpty, objToParams, paramsToObj } from '@utils/helpers';
import { setError } from '@store/reducers/appReducer';
import error2Text from '@utils/error2Text';
import MyTable from '@components/MyTable';
import MyLoadingButton from '@components/MyLoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { generalColumns, conditionList, statusList } from '../enhance';

export default function OfficeConsultationInquiry() {
  const { t } = useTranslation();
  const { formatTime } = Formatter;
  const dispatch = useDispatch();
  const configColumns = generalColumns;
  const navigate = useNavigate();
  const location = useLocation();
  const [param, setParam] = useState({
    startDate: formatTime(sub(new Date(), { months: 1 }), dateFormat),
    endDate: formatTime(new Date(), dateFormat),
    contactYn: 'all',
    companyName: null,
    page: 0
  });
  const [state, setState] = useState({
    items: [],
    pagination: {
      page: 0,
      count: 0,
      totalRow: 0
    }
  });

  const [condition, setCondition] = useState(conditionList[0].value);
  const keywordRef = useRef();

  const handleChangePage = (page) => {
    setParam({ ...param, page });
    const query = objToParams(ignoreEmpty({ ...param, page: page + 1 }));
    navigate(query);
  };
  const handleSearch = () => {
    const dataParam = {
      ...param,

      companyName: keywordRef.current?.value || null
    };
    const query = objToParams(ignoreEmpty({ ...dataParam, page: 1 }));
    getConsultationInquiryList({
      ...dataParam,
      contactYn: param?.contactYn === 'all' ? null : param.contactYn,
      page: 0
    });
    navigate(query);
  };

  const getConsultationInquiryList = async (param) => {
    const rs = await consultationInquiryService.getList({
      ...param,
      entryType: 'GENERAL'
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    } else {
      setState({
        ...state,
        items: rs.data?.items || [],
        pagination: {
          ...state?.pagination,
          page: rs.data?.page || 0,
          totalRow: rs.data?.totalRow || 0,
          count: rs.data?.totalPage || 0
        }
      });
    }
  };

  useEffect(() => {
    const query = paramsToObj(location?.search);
    const { startDate, endDate, contactYn, companyName, page } = query;
    // eslint-disable-next-line prefer-template
    const firstDayOfMonth = Formatter.formatTime(new Date(), 'YYYY-MM') + '-01';
    keywordRef.current.value = companyName || '';
    setParam({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      companyName,
      contactYn: contactYn || 'all',
      page: page - 1 || 0
    });
    setCondition('companyName');
    getConsultationInquiryList({
      ...param,
      startDate: formatTime(startDate || firstDayOfMonth, dateFormat),
      endDate: formatTime(endDate || new Date(), dateFormat),
      companyName,
      contactYn: contactYn === 'all' ? null : contactYn,
      page: page - 1 || 0
    });
  }, [location?.search]);

  const handleDatetime = (e) => {
    setParam({
      ...param,
      startDate: formatTime(e.startDate, dateFormat),
      endDate: formatTime(e.endDate, dateFormat)
    });
  };

  const handleRowClick = (row) => {
    navigate(`${row.inquiryCode}`);
  };

  const handleDownloadExcel = async () => {
    const rs = await consultationInquiryService.exportConsultationIqnuiry({
      ...param,
      contactYn: param?.contactYn === 'all' ? null : param?.contactYn,
      entryType: 'GENERAL'
    });
    if (rs?.error) {
      dispatch(setError(error2Text(rs?.error)));
    }
  };

  return (
    <HelmetContainer
      title={t('pageTitle.intro-inquiry-general')}
      content="Service Inquiry Page"
    >
      <SearchBar>
        <FormControl>
          <FormControlLabel
            label={t('common.period')}
            labelPlacement="start"
            sx={{
              '.MuiTypography-root': {
                color: '#6F869C',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
                mr: 1
              }
            }}
            control={
              <DateRangePicker
                state={{
                  startDate: new Date(param.startDate),
                  endDate: new Date(param.endDate),
                  key: 'selection'
                }}
                setState={handleDatetime}
              />
            }
          />
        </FormControl>

        <MySelect
          data={statusList}
          label={t('labels.type')}
          onChange={(e) => setParam({ ...param, contactYn: e?.target?.value })}
          value={param?.contactYn}
        />

        <Stack flex={1} sx={{ minWidth: 300, maxWidth: 400 }}>
          <MyTextField
            label={t('pages.consultationInquiry.companyName')}
            placeholder={t('common.input-keyword')}
            ref={keywordRef}
            autoFocus
            onKeyDown={(e) => {
              if (e.isComposing || e.keyCode === 13) {
                handleSearch();
              }
            }}
            sx={{ minWidth: 300, textfield: { width: '300px' } }}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ ml: 1.5, width: '80px' }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
      </SearchBar>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: '30px' }}>
        <MyLoadingButton variant="outlined" onClick={handleDownloadExcel}>
          {`${t('pageTitle.intro-inquiry')} ${t('button.download')}`}
        </MyLoadingButton>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              fontWeight: 'fontWeightSemiMedium',
              fontSize: 15,
              color: 'text.primary'
            }}
            component="span"
          >
            {t('common.number-of-results')}{' '}
            <Typography
              component="span"
              sx={{ fontWeight: 'fontWeightBold', color: 'primary.dark' }}
            >
              {state?.pagination?.totalRow || 0}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <MyTable
        hover
        onRowClick={handleRowClick}
        rerender
        columns={configColumns}
        data={state?.items || []}
        pagination={state?.pagination}
        minusHeight={290}
        onChangePage={(e, page) => handleChangePage(page)}
      />
    </HelmetContainer>
  );
}
