import { Formatter } from '@utils/formatter';
import i18n from '@utils/i18n';

const g = i18n.getFixedT(null, null, '');
const t = i18n.getFixedT(null, null, 'pages.homepage');

export const faqCategoryList = [
  {
    value: 'all',
    label: t('faq-category.all')
  },
  {
    value: 'PRODUCT',
    label: t('faq-category.product')
  },
  {
    value: 'FACILITY',
    label: t('faq-category.facility')
  },
  {
    value: 'SERVICE',
    label: t('faq-category.service')
  },
  {
    value: 'SETTLE',
    label: t('faq-category.calculation')
  },
  {
    value: 'SHIPPING',
    label: t('faq-category.shipping')
  },
  {
    value: 'CSAS',
    label: t('faq-category.cs')
  }
];

const faqCategoryObject = {
  PRODUCT: t('faq-category.product'),
  FACILITY: t('faq-category.facility'),
  SERVICE: t('faq-category.service'),
  SETTLE: t('faq-category.calculation'),
  SHIPPING: t('faq-category.shipping'),
  CSAS: t('faq-category.cs')
};
export const columns = (data) => [
  {
    accessorKey: '#',
    header: 'No',
    size: 40,
    align: 'center',
    Cell: ({ cell }) => Number(cell.row.id) + 1
  },
  {
    accessorKey: 'category',
    header: g('pages.product.category'),
    minSize: 80,
    maxSize: 120,
    Cell: ({ cell }) => faqCategoryObject[cell?.getValue()] || cell?.getValue
  },
  {
    accessorKey: 'question',
    header: t('faq'),
    minSize: 100,
    maxSize: 300
  },
  {
    accessorKey: 'createdBy',
    header: t('register-admin'),
    minSize: 80,
    maxSize: 100,
    align: 'center'
  },
  {
    accessorKey: 'createdDate',
    header: t('reg-dt'),
    minSize: 80,
    maxSize: 150,
    align: 'center',
    Cell: ({ cell }) =>
      new Date(cell?.getValue()).toLocaleString('en-CA', {
        hour12: false,
        timeZone: 'Asia/Seoul'
      })
  },
  {
    accessorKey: 'updatedBy',
    header: t('edit-admin'),
    minSize: 80,
    maxSize: 100,
    align: 'center'
  },
  {
    accessorKey: 'updatedDate',
    header: t('edit-dt'),
    minSize: 80,
    maxSize: 150,
    align: 'center',
    Cell: ({ cell }) =>
      cell?.getValue()
        ? new Date(cell?.getValue()).toLocaleString('en-CA', {
            hour12: false,
            timeZone: 'Asia/Seoul'
          })
        : ''
  },
  // {
  //   accessorKey: 'isDisplayed',
  //   header: `${t('include-in-flagshop')} (${data?.totalDisplayedFaq || 0}/6)`,
  //   minSize: 80,
  //   maxSize: 140,
  //   align: 'center',
  //   action: true,
  //   actionType: 1
  // },
  {
    accessorKey: 'delete',
    header: g('button.delete'),
    size: 20,
    align: 'center',
    action: true,
    actionType: 2
  }
];

export const productDetailSchema = [
  {
    name: 'category',
    type: 'select',
    label: g('pages.product.category'),
    grid: 12,
    inputStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    options: faqCategoryList,
    border: { borderLeft: 0 }
  },
  // {
  //   name: 'category',
  //   grid: 6
  // },
  {
    name: 'createdBy',
    type: 'text',
    label: t('register-admin'),
    disabled: true,
    grid: 6,

    border: { borderLeft: 0 }
  },
  {
    name: 'updatedBy',
    type: 'text',
    label: t('edit-admin'),
    disabled: true,
    grid: 6,
    border: { borderLeft: '1px solid #D3D3D3' }
  },
  {
    name: 'createdDate',
    type: 'text',
    label: t('reg-dt'),
    disabled: true,
    grid: 6,
    formatter: (val) => val && Formatter.formatTime(val),
    border: { borderLeft: 0 }
  },
  {
    name: 'updatedDate',
    type: 'text',
    label: t('edit-dt'),
    formatter: (val) => val && Formatter.formatTime(val),
    disabled: true,
    grid: 6
  },
  {
    name: 'question',
    type: 'text',
    label: t('question-ko'),
    grid: 6,
    border: { borderLeft: 0 }
  },
  {
    name: 'questionEng',
    type: 'text',
    label: t('question-en'),
    grid: 6,
    border: { borderLeft: '1px solid #D3D3D3' }
  },

  {
    name: 'answer',
    type: 'textarea',
    label: t('answer-ko'),
    grid: 12,
    border: { borderLeft: 0 }
  },
  {
    name: 'answerEng',
    type: 'textarea',
    label: t('answer-en'),
    grid: 12,
    border: { borderLeft: 0 }
  }
];
export const tableStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      minWidth: 120
    },
    td: {
      p: '10px 12px',
      width: 480
    }
  },
  '.MuiTableCell-root': {
    p: '0 12px',
    borderBottom: 'none'
  }
};

export const tableBodyStyle = {
  '.MuiTableRow-root': {
    ':not(:last-child)': {
      borderBottom: '1px solid #EEEEEE'
    },
    th: {
      borderRight: '1px solid #EEEEEE',
      fontWeight: 'fontWeightBold',
      fontSize: 13,
      textAlign: 'center',
      color: '#6F869C',
      bgcolor: '#FCFCFC',
      width: 120,
      whiteSpace: 'nowrap'
    },
    td: {
      borderRight: '1px solid #EEEEEE',
      height: 60,
      fontSize: 14,
      color: 'text.primary'
    }
  },
  '.MuiTableCell-root': { p: '0px 12px', borderBottom: 'none' }
};
