import {
  BuildingIcon,
  DonutChartIcon,
  DrawerIcon3,
  DrawerIcon6,
  DrawerIcon7,
  DrawerIcon8,
  DrawerIcon9,
  LineChartIcon,
  OrderIcon,
  PanelIcon,
  SettingIcon,
  HomeIcon,
  PhoneIcon
} from '@svg/drawer';

import i18n from './i18n';

const sidebarConfig = [
  {
    title: i18n.t('menusidebar.dashboard.label'),
    path: '/dashboard',
    Icon: PanelIcon
  },
  {
    title: i18n.t('menusidebar.client-management.label'),
    path: '/client-management',
    Icon: BuildingIcon,
    children: [
      {
        title: i18n.t('menusidebar.client-management.client'),
        path: '/client-management/client'
      },
      {
        title: i18n.t('menusidebar.client-management.minibar'),
        path: '/client-management/minibar'
      },
      {
        title: i18n.t('menusidebar.client-management.minibarGroup'),
        path: '/client-management/minibar-group'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.equipment-management.label'),
    path: '/equipment-management',
    Icon: DrawerIcon3,
    children: [
      {
        title: i18n.t('menusidebar.equipment-management.equipment'),
        path: '/equipment-management/equipment'
      },
      {
        title: i18n.t('menusidebar.equipment-management.equipmentHistory'),
        path: '/equipment-management/history'
      },
      {
        title: i18n.t('menusidebar.equipment-management.by-minibar'),
        path: '/equipment-management/by-minibar'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.performance-management.label'),
    path: '/performance-management',
    Icon: LineChartIcon,
    children: [
      {
        title: i18n.t('menusidebar.product-management.total-order-history'),
        path: '/performance-management/total-order-history'
      },
      {
        title: i18n.t('menusidebar.member-management.order-history'),
        path: '/performance-management/member-order-history'
      },
      {
        title: i18n.t('menusidebar.performance-management.goal'),
        path: '/performance-management/goal'
      },
      {
        title: i18n.t('menusidebar.performance-management.sales'),
        path: '/performance-management/sales'
      },
      {
        title: i18n.t('menusidebar.performance-management.profit-and-loss'),
        path: '/performance-management/profit-and-loss'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.statistics.label'),
    path: '/statistics',
    Icon: DonutChartIcon,
    children: [
      {
        title: i18n.t('menusidebar.statistics.sales'),
        path: '/statistics/sales'
      },
      {
        title: i18n.t('menusidebar.statistics.by-member'),
        path: '/statistics/by-member'
      },
      {
        title: i18n.t('menusidebar.statistics.by-product'),
        path: '/statistics/by-product'
      },
      {
        title: i18n.t('menusidebar.statistics.member'),
        path: '/statistics/member'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.member-management.label'),
    path: '/member-management',
    Icon: DrawerIcon6,
    children: [
      {
        title: i18n.t('menusidebar.member-management.member'),
        path: '/member-management/member'
      },
      {
        title: i18n.t('menusidebar.member-management.welfare-points'),
        path: '/member-management/welfare-points'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.product-management.label'),
    path: '/product-management',
    Icon: DrawerIcon7,
    children: [
      {
        title: i18n.t('menusidebar.product-management.category'),
        path: '/product-management/category'
      },
      {
        title: i18n.t('menusidebar.product-management.product'),
        path: '/product-management/product'
      },
      {
        title: i18n.t('menusidebar.product-management.price-policy-type'),
        path: '/product-management/price-policy-type'
      },
      {
        title: i18n.t('menusidebar.product-management.curation'),
        path: '/product-management/curation'
      },
      {
        title: i18n.t('menusidebar.product-management.minibar-order'),
        path: '/product-management/minibar-order'
      },
      {
        title: i18n.t('menusidebar.product-management.display-history'),
        path: '/product-management/display-history'
      },
      {
        title: i18n.t('menusidebar.product-management.client-order'),
        path: '/product-management/client-order'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.inventory-management.label'),
    path: '/inventory-management',
    Icon: DrawerIcon8,
    children: [
      {
        title: i18n.t('menusidebar.inventory-management.location'),
        path: '/inventory-management/location'
      },
      {
        title: i18n.t('menusidebar.inventory-management.warehouse'),
        path: '/inventory-management/warehouse'
      },
      {
        title: i18n.t('pageTitle.stocking'),
        path: '/inventory-management/stocking'
      },
      {
        title: i18n.t('menusidebar.inventory-management.warehouseDisplay'),
        path: '/inventory-management/warehouse-display'
      },
      {
        title: i18n.t('menusidebar.inventory-management.picking'),
        path: '/inventory-management/picking'
      },
      {
        title: i18n.t('menusidebar.inventory-management.release'),
        path: '/inventory-management/release'
      },
      {
        title: i18n.t('menusidebar.inventory-management.display'),
        path: '/inventory-management/display'
      },
      {
        title: i18n.t('menusidebar.inventory-management.restock'),
        path: '/inventory-management/restock'
      },
      {
        title: i18n.t('pageTitle.stockTaking'),
        path: '/inventory-management/stockTaking'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.display-management.label'),
    path: '/display-management',
    Icon: DrawerIcon9,
    children: [
      {
        title: i18n.t('menusidebar.display-management.PIC'),
        path: '/display-management/PIC'
      },
      {
        title: i18n.t('menusidebar.display-management.schedule'),
        path: '/display-management/schedule'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.order-management.label'),
    path: '/order-management',
    Icon: OrderIcon,
    children: [
      {
        title: i18n.t('menusidebar.inventory-management.vendor'),
        path: '/order-management/vendor-management'
      },
      {
        title: i18n.t('menusidebar.order-management.vendor'),
        path: '/order-management/vendor'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.manager.label'),
    path: '/manager',
    Icon: SettingIcon,
    children: [
      {
        title: i18n.t('menusidebar.manager.development-request'),
        path: '/manager/development-request'
      },
      {
        title: i18n.t('menusidebar.manager.authority'),
        path: '/manager/authority'
      },
      {
        title: i18n.t('menusidebar.manager.kiosk'),
        path: '/manager/kiosk'
      },
      {
        title: i18n.t('menusidebar.manager.APK'),
        path: '/manager/APK'
      },
      { title: i18n.t('menusidebar.manager.AD'), path: '/manager/AD' },
      {
        title: i18n.t('menusidebar.manager.usage-history'),
        path: '/manager/usage-history'
      },
      {
        title: i18n.t('menusidebar.manager.log'),
        path: '/manager/log'
      },
      {
        title: i18n.t('menusidebar.manager.system-log'),
        path: '/manager/system-log'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.homepage.label'),
    path: '/homepage',
    Icon: HomeIcon,
    children: [
      {
        title: i18n.t('menusidebar.homepage.intro-inquiry'),
        path: '/homepage/consultation-inquiry',
        children: [
          {
            title: i18n.t('menusidebar.homepage.office'),
            path: '/homepage/consultation-inquiry/office'
          },
          {
            title: i18n.t('menusidebar.homepage.general'),
            path: '/homepage/consultation-inquiry/general'
          }
        ]
      },
      {
        title: i18n.t('menusidebar.homepage.service-intro-inquiry'),
        path: '/homepage/service-introduction-inquiry',
        children: [
          {
            title: i18n.t('menusidebar.homepage.office'),
            path: '/homepage/service-introduction-inquiry/office'
          },
          {
            title: i18n.t('menusidebar.homepage.general'),
            path: '/homepage/service-introduction-inquiry/general'
          },
          {
            title: i18n.t('menusidebar.homepage.ads'),
            path: '/homepage/service-introduction-inquiry/ads'
          }
        ]
      },
      {
        title: i18n.t('menusidebar.homepage.media'),
        path: '/homepage/media'
      },
      {
        title: i18n.t('menusidebar.homepage.notices'),
        path: '/homepage/notices'
      },
      {
        title: i18n.t('menusidebar.homepage.faq'),
        path: '/homepage/faq'
      },
      {
        title: i18n.t('menusidebar.homepage.talent-hire'),
        path: '/homepage/careers'
      }
    ]
  },
  {
    title: i18n.t('menusidebar.pre-order.label'),
    path: '/pre-order',
    Icon: PhoneIcon,
    children: [
      {
        title: i18n.t('menusidebar.pre-order.category'),
        path: '/pre-order/category'
      },
      {
        title: i18n.t('menusidebar.pre-order.store'),
        path: '/pre-order/store'
      },
      // {
      //   title: i18n.t('menusidebar.pre-order.product'),
      //   path: '/pre-order/product'
      // },
      {
        title: i18n.t('menusidebar.pre-order.order-history'),
        path: '/pre-order/order-history'
      }
    ]
  }
];
export const displayManageConfig = [
  {
    title: i18n.t('menusidebar.product-management.minibar-order'),
    path: '/product-management/minibar-order/display',
    Icon: DrawerIcon7
  }
];

export default sidebarConfig;
