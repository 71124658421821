/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import { matchPath, useLocation, NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Stack
} from '@mui/material';

import { ReactComponent as PlusIcon } from '@svg/plus.svg';
import { ReactComponent as MinusIcon } from '@svg/minus.svg';
import { useDispatch } from 'react-redux';
import { setRerender } from '@store/reducers/appReducer';

const NavItem = ({ item, active, pathname, handleDrawerClose, isSm }) => {
  const { title, path, Icon, children } = item;
  const isActiveRoot = active(path);
  const [open, setOpen] = useState(isActiveRoot);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!pathname.startsWith(path)) setOpen(false);
  }, [pathname, path]);

  const hoverStyle = {
    ':hover': { color: 'primary.dark' }
  };

  const rootItemStyle = {
    px: '25px',
    py: '15px',
    fontSize: 15,
    ...(isActiveRoot
      ? {
          color: 'primary.darker',
          bgcolor: 'secondary.lighter',
          fontWeight: 'fontWeightBold'
        }
      : hoverStyle)
  };

  const itemStyle = {
    px: '25px',
    py: '15px',
    fontSize: 15,
    ...(open || pathname.startsWith(path)
      ? {
          color: 'primary.darker',
          fontWeight: 'fontWeightBold'
        }
      : hoverStyle)
  };

  const getChildItemStyle = (isActiveSub, level = 1) => ({
    pl: level === 1 ? '54px' : '72px',
    color: 'text.primary',
    fontSize: level === 1 ? 13 : 12,
    fontWeight: 'fontWeightSemiMedium',
    ...(isActiveSub
      ? { color: 'primary.darker', fontWeight: 'fontWeightBold' }
      : hoverStyle)
  });

  const SubNavItem = ({ subItem, level = 1 }) => {
    const { title, path, Icon, children: grandChildren } = subItem;
    const isActiveSub = active(path);
    const [subOpen, setSubOpen] = useState(isActiveSub);

    useEffect(() => {
      if (!pathname.startsWith(path)) setSubOpen(false);
    }, [pathname, path]);

    if (grandChildren) {
      return (
        <Box>
          <ListItemButton
            onClick={() => setSubOpen(!subOpen)}
            sx={getChildItemStyle(isActiveSub, level)}
          >
            {Icon && (
              <ListItemIcon>
                <Icon fill="currentColor" />
              </ListItemIcon>
            )}
            <ListItemText
              disableTypography
              primary={title}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mr: 1.5
              }}
            />
            {subOpen ? (
              <MinusIcon fill="currentColor" />
            ) : (
              <PlusIcon fill="currentColor" />
            )}
          </ListItemButton>
          <Collapse in={subOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {grandChildren.map((grandChild) => (
                <ListItemButton
                  key={grandChild.path}
                  component={NavLink}
                  to={grandChild.path}
                  sx={getChildItemStyle(active(grandChild.path), level + 1)}
                  onClick={() => {
                    dispatch(setRerender(true));
                    if (active(grandChild.path)) {
                      setTimeout(() => {
                        window.location.reload();
                      }, 0);
                    }
                    if (isSm) {
                      handleDrawerClose();
                    }
                  }}
                >
                  {grandChild.Icon && (
                    <ListItemIcon>
                      <grandChild.Icon fill="currentColor" />
                    </ListItemIcon>
                  )}
                  <ListItemText primary={grandChild.title} disableTypography />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </Box>
      );
    }

    return (
      <ListItemButton
        component={NavLink}
        to={path}
        sx={getChildItemStyle(isActiveSub, level)}
        onClick={() => {
          dispatch(setRerender(true));
          if (isActiveSub) {
            setTimeout(() => {
              window.location.reload();
            }, 0);
          }
          if (isSm) {
            handleDrawerClose();
          }
        }}
      >
        {Icon && (
          <ListItemIcon>
            <Icon fill="currentColor" />
          </ListItemIcon>
        )}
        <ListItemText primary={title} disableTypography />
      </ListItemButton>
    );
  };

  if (children) {
    return (
      <Box
        sx={{
          bgcolor:
            open || pathname.startsWith(path) ? 'secondary.lighter' : 'unset'
        }}
      >
        <ListItemButton onClick={() => setOpen(!open)} sx={itemStyle}>
          <ListItemIcon>
            <Icon fill="currentColor" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={title}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mr: 1.5
            }}
          />
          {open ? (
            <MinusIcon fill="currentColor" />
          ) : (
            <PlusIcon fill="currentColor" />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => (
              <SubNavItem key={item.path} subItem={item} />
            ))}
          </List>
        </Collapse>
      </Box>
    );
  }

  return (
    <ListItemButton component={NavLink} to={path} sx={rootItemStyle}>
      <ListItemIcon>
        <Icon fill="currentColor" />
      </ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemButton>
  );
};

export default function SidebarMenu({
  navConfig,
  headerHeight,
  handleDrawerClose,
  isDrawerOpen,
  isSm
}) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <SimpleBar
      forceVisible="y"
      autoHide
      style={{ maxHeight: `calc(100% - ${headerHeight + 92}px)` }}
    >
      <List disablePadding sx={{ py: 2 }}>
        {navConfig?.map((item) => (
          <NavItem
            key={item.title}
            item={item}
            active={match}
            handleDrawerClose={handleDrawerClose}
            isDrawerOpen={isDrawerOpen}
            isSm={isSm}
            pathname={pathname}
          />
        ))}
      </List>
      <Stack sx={{ visibility: 'hidden', minHeight: '150px' }}>
        Sidebar Footer
      </Stack>
    </SimpleBar>
  );
}
