/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { useMemo } from 'react';
import {
  MRT_TableContainer,
  useMaterialReactTable
} from 'material-react-table';
import { Stack } from '@mui/material';

const RowDND = ({
  maxHeight,
  minusHeight = 180,
  onRowClick,
  columns,
  getActionButtons,
  data,
  enableRowDrag = true,
  onRowDrag
}) => {
  // Enhanced columns to include action buttons if column has action === true
  const enhancedColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.action) {
        return {
          ...column,
          Cell: ({ row }) => (
            <Stack
              onClick={(event) => {
                event.stopPropagation(); // Stop the event from propagating to the row click
              }}
              sx={{ height: '46px', padding: 0 }}
            >
              {getActionButtons(row.original, column.actionType)}
            </Stack>
          )
        };
      }
      return column;
    });
  }, [columns, getActionButtons]);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns: enhancedColumns,
    data,
    selectAllMode: 'all',
    enableColumnFilterModes: false,
    enableColumnActions: false,
    manualPagination: true,
    rowCount: data?.length,
    // initialState: {
    //   pagination: { pageSize: 20 } // Ensure all rows are displayed
    // },
    muiTableContainerProps: {
      sx: { border: '1px solid #D3D3D3', borderRadius: '12px' },
      style: {
        maxHeight: maxHeight || `calc(100vh - ${minusHeight + 200}px)`
      }
    },
    muiTableHeadProps: {
      sx: {
        '.MuiTableCell-root': {
          p: 0,
          height: '50px',
          fontWeight: 'fontWeightBold',
          fontSize: '13px',
          color: '#6F869C',
          lineHeight: '18px',
          alignItems: 'center',
          backgroundColor: '#faf9f9 !important',
          justifyContent: 'center',
          borderBottom: '1px solid #D3D3D3',
          '&:not(:last-child)': {
            borderRight: '1px solid #EEEEEE'
          }
        },
        position: 'relative',
        zIndex: 1
      }
    },
    muiTableHeadCellProps: {
      align: 'center',
      justifyContent: 'center',
      sx: {
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'center'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
      sx: {
        py: 0,

        '&:last-child td, &:last-child th': { border: 0 },
        'td:not(:last-child), th:not(:last-child)': {
          borderRight: '1px solid #EEEEEE'
        },
        ':hover': {
          '.MuiTableCell-root': {
            backgroundColor: '#F6FAFF !important',
            color: 'primary.dark'
          },
          cursor: 'pointer'
        }
      }
    }),
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        py: 0.5,
        px: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    enableRowOrdering: enableRowDrag && data?.length > 1,
    enableSorting: false,
    displayColumnDefOptions: {
      'mrt-row-drag': {
        header: ''
      }
    },

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();

        if (hoveredRow && draggingRow) {
          onRowDrag(draggingRow, hoveredRow);
        }
      }
    })
  });

  return <MRT_TableContainer table={table} />;
};

export default RowDND;
